import { gql, useQuery } from '@apollo/client'
import { SearchItemsQuery, SearchItemsQueryVariables } from 'cl-studio'
import React from 'react'

import { itemFragment } from './apollo_fragments/items'
import TemplateItems from './components/TemplateItems'

const SEARCH_ITEMS_QUERY = gql`
  query SearchItems($query: String, $explorerId: Int) {
    templates {
      id
      title
    }
    searchItems(query: $query, explorerId: $explorerId) {
      ...itemFields
      parentItemTitle
    }
  }
  ${itemFragment}
`

interface Props {
  toggleToolbar: () => void
  query: string
  explorerId: number
}

function SearchItemsView({ toggleToolbar, query, explorerId }: Props) {
  const { data, loading } = useQuery<
    SearchItemsQuery,
    SearchItemsQueryVariables
  >(SEARCH_ITEMS_QUERY, {
    variables: {
      query,
      explorerId,
    },
  })

  if (loading || !data) {
    return null
  }

  return (
    <div className="result-box" data-cy="search-items">
      <div className="result-box-tag-info" />
      <TemplateItems
        items={data.searchItems}
        templates={data.templates}
        toggleToolbar={toggleToolbar}
      />
    </div>
  )
}
export default SearchItemsView
