import { settings } from '@cls/config'
import Logo from '@cls/Logo'
import { History } from 'history'
import React, { PureComponent } from 'react'

import { login } from '../state/actions-login'

type Props = {
  dispatch: (...args: Array<any>) => any
  loginPending: boolean
  loggedIn: boolean
  loginError?: string
  history: History
}

type State = any

export default class LoginForm extends PureComponent<Props, State> {
  refPassword: any

  constructor(props: Props) {
    super(props)
    this.state = {
      error: '',
      loggingIn: false,
    }
  }

  onSubmit = (event: any) => {
    const { dispatch } = this.props
    event.preventDefault()

    dispatch(
      login({
        email: this.refEmail.value,
        password: this.refPassword.value,
      })
    )
  }

  onPasswordReset = () => {
    this.props.history.push({
      pathname: '/resetpassword',
      state: {
        ...((this.props.history.location.state as any) || {}),
        email: this.refEmail.value,
      },
    })
  }

  refEmail = { value: null }
  setRefEmail = (el: any) => (this.refEmail = el)
  setRefPassword = (el: any) => (this.refPassword = el)

  render() {
    const { loginPending, loggedIn, loginError } = this.props
    const loginURL = `${settings.apiServer}/login`
    let errorMsg, loginButton

    if (loginError) {
      errorMsg = <p className="form-error">{loginError}</p>
    }

    if (loginPending || loggedIn) {
      loginButton = (
        <button type="submit" className="btn btn-primary login-button" disabled>
          Logging in...
        </button>
      )
    } else {
      loginButton = (
        <button type="submit" className="btn btn-primary login-button">
          Login
        </button>
      )
    }

    return (
      <form
        method="post"
        action={loginURL}
        className="form login-form center"
        onSubmit={this.onSubmit}
        data-cy="login-form"
      >
        <Logo />
        {errorMsg}
        <fieldset className="fieldset">
          <p className="form-row">
            <label htmlFor="login-email">Email</label>
            <input
              type="email"
              placeholder="Email"
              name="email"
              id="login-email"
              className="input input-email block"
              ref={this.setRefEmail}
              autoComplete="email"
              data-cy="login-email"
            />
          </p>

          <p className="form-row">
            <label htmlFor="login-password">Password</label>
            <input
              type="password"
              placeholder="Password"
              name="password"
              id="login-password"
              className="input input-password block"
              ref={this.setRefPassword}
              autoComplete="current-passsword"
              data-cy="login-password"
            />
          </p>
        </fieldset>
        <fieldset>
          <a className="item-row-link" onClick={this.onPasswordReset}>
            <p>Forgot password</p>
          </a>
        </fieldset>
        <fieldset className="fieldset">{loginButton}</fieldset>
      </form>
    )
  }
}
