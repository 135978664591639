import { settings } from '@cls/config'
import { purgeCookie, setCookie } from '@cls/utils/cookies'
import { checkHttpStatus, parseJSON } from '@cls/utils/fetch'
import * as Sentry from '@sentry/browser'
import fetch from 'isomorphic-fetch'

import * as types from './action-types'

const viewerRoles = ['viewer_v1', 'viewer_v2', 'viewer']

// action dispatchers
export function login(params = {}) {
  return (dispatch: any) => {
    dispatch(loginRequest())

    const url = `${settings.apiServer}/login`
    const options = {
      method:  'post',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
      body:    JSON.stringify(params),
    }

    return doLogin(url, options, dispatch)
  }
}

export function logoutUser() {
  return (dispatch: any) => {
    dispatch({ type: types.LOGOUT })

    const url = `${settings.apiServer}/logout`
    const options = {
      method:  'post',
      headers: { Accept: 'application/json', 'Content-Type': 'application/json' },
    }
    return fetch(url, options)
      .then(checkHttpStatus)
      .catch(err => {
        console.error(err)
        return
      })
      .finally(() => {
        purgeCookie(settings.jwtKey)
      })
  }
}

function doLogin(url: string, options: RequestInit, dispatch: any) {
  let failure = false
  return fetch(url, options)
    .then(checkHttpStatus)
    .then(parseJSON)
    .catch(err => {
      failure = true
      dispatch(loginFailure(err))
      return
    })
    .then(data => {
      if (data && data.profile) {
        if (viewerRoles.includes(data.profile.role)) {
          dispatch(
            loginFailure({
              response: {
                status: 403,
                statusText: 'Viewers are not allowed to access the backend',
              },
            })
          )
          return
        }
        dispatch(loginSuccess(data))
      } else if (!failure) {
        dispatch(loginFailure({ response: { status: 403, statusText: 'Invalid token' } }))
      }
    })
}

// action creators
export function loginRequest() {
  return {
    type: types.LOGIN_REQUEST,
  }
}

export function loginSuccess(data: any) {
  setCookie(settings.jwtKey, data.token, data.expires)
  if (settings.useSentry) {
    Sentry.configureScope(scope => {
      scope.setUser({
        email:    data.profile.email,
        username: data.profile.name,
      })
    })
  }
  return {
    type: types.LOGIN_SUCCESS,
    data,
  }
}

export function loginFailure(error?: any) {
  purgeCookie(settings.jwtKey)
  const errorText =
    error && error.response && error.response.status === 401
      ? 'Invalid email or password'
      : (error && error.response && error.response.statusText) ||
        'Server problems, please try again...'

  return {
    type: types.LOGIN_FAILURE,
    data: errorText,
  }
}
