import { Input } from '@kpv-lab/ui'
import dateformat from 'dateformat'
import React from 'react'

const adminRoles = ['admin', 'editor']

type Props = {
  item: any
  editModeActive: boolean
  handleEditReply?: (key: 'title' | 'body', value: string) => any
  handleSaveReplyEdit?: () => void
  handleCancelReplyEdit?: () => void
}

export default function EditMessage({
  item,
  handleEditReply,
  editModeActive,
  handleSaveReplyEdit,
  handleCancelReplyEdit,
}: Props) {
  const id = item.id
  const body = item.body
  const hour = dateformat(item.created_at, 'HH:MM')
  const day = dateformat(item.created_at, 'd mmm yyyy')
  const user = item.user.name
  const title = item.title
  const userRole = item.user.role
  const isAdmin = adminRoles.includes(userRole)

  let className = 'feedback-msg-username',
    starIcon
  if (isAdmin) {
    starIcon = <i className="material-icons user-admin-icon">star</i>
    className += ' user-admin'
  }

  const author = (
    <div className="feedback-msg-by">
      {starIcon}
      <span className={className}>{user}</span>
    </div>
  )

  const time = (
    <span>
      {' '}
      at {hour} on {day}
    </span>
  )
  const msgTitle = <span>{title}&nbsp;by&nbsp;</span>

  return (
    <div key={`f-msg-${id}`} className="feedback-msg">
      <div className="feedback-msg-header">
        {title ? msgTitle : ''}
        {author}&nbsp;{time}
      </div>
      <div className="feedback-msg-body">
        {editModeActive ? (
          <div className="edit-container">
            <Input
              dataKey="body"
              structure={{
                type: 'textarea',
                updateOnBlur: false,
                options: { placeholder: 'Write a reply...' },
              }}
              value={body}
              focus={true}
              // eslint-disable-next-line
              // @ts-ignore
              updateHandler={handleEditReply}
              inline={false}
            />
            <div className="btn-group">
              <button
                className="btn btn-primary reply-btn"
                onClick={handleSaveReplyEdit}
              >
                Submit
              </button>
              <button
                onClick={handleCancelReplyEdit}
                className="btn btn-secondary cancel-btn"
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <span>{body}</span>
        )}
      </div>
    </div>
  )
}
