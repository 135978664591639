import { settings } from '@cls/config'
import fetch from 'isomorphic-fetch'

/*
 - We speak to our own server to get a signedURL from AWS
 - We then directly upload to the S3 bucket from the client, using the signed URL
*/

export const requestUploadURL = async (file: File) => {
  const options = {
    credentials: 'same-origin',
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  } as RequestInit

  const type = file.type
  let extension = type.substring(type.lastIndexOf('/') + 1)

  if (extension === 'svg+xml') {
    extension = 'svg'
  }

  if (extension === 'ogg') {
    extension = 'audio-ogg'
  }

  const url = `${settings.apiServer}/sign-s3/${extension}`

  try {
    const response = await fetch(url, options)

    if (!response.ok) {
      throw new Error(`Response not ok: ${response.statusText}`)
    }
    return response.json()
  } catch (error) {
    throw new Error(`Requesting upload URL failed, ${error}`)
  }
}

export const uploadFileToS3 = (file: File, url: string): Promise<void> => {
  return new Promise((resolve, reject) => {
    const xhrObj = new XMLHttpRequest()

    xhrObj.addEventListener('readystatechange', function () {
      if (this.status >= 300) {
        reject(new Error('File upload failed'))
      }
    })

    xhrObj.upload.onload = function () {
      resolve()
    }

    xhrObj.open('PUT', url, true)
    xhrObj.setRequestHeader('Content-Type', file.type)
    xhrObj.setRequestHeader('Cache-Control', '31536000')
    xhrObj.setRequestHeader('x-amz-acl', 'public-read')
    xhrObj.send(file)
  })
}
